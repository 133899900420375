import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/template.css';
import '../styles/about.css';
import '../styles/team.css';

import sportsBackground from '../images/sports-background.jpeg';
import elliot from '../images/team/elliot.png';
import carl from '../images/team/carl.png';
import playerGlobe from '../images/player-globe.png';
import Layout from '../components/Layout';

const Team = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Team</title>
      </Helmet>
      <main id="team">
        <section id="hero">
          <img id="hero-img" src={sportsBackground} />
          <div className="page-content">
            <h4>Workers</h4>
            <h1>Team</h1>
          </div>
        </section>
        <section className="content-section">
          <div className="page-content">
            <div className="col">
              <p className="large">
                Elliot & Carl are tech entrepreneurs who built Racqy together
                from scratch with 0 funding. They have joined forces to disrupt
                the sports industry with revolutionising technology.
              </p>

              <div className="team">
                <div className="member">
                  <img src={elliot} />
                  <div className="member-info">
                    <h4>Elliot Bennet</h4>
                    <h6>Co-founder</h6>
                    <p>Email: elliot@racqy.com</p>
                    <p>Phone: +46 70-648 15 28</p>
                  </div>
                </div>
                <div className="member">
                  <img src={carl} />
                  <div className="member-info">
                    <h4>Carl Båge</h4>
                    <h6>Co-founder</h6>
                    <p>Email: carl@racqy.com</p>
                    <p>Phone: +46 72-502 25 05</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img src={playerGlobe} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Team;
